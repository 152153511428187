var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._m(0),
      _c(
        "b-card",
        { attrs: { "bg-variant": "light" } },
        [
          _c("b-container", { staticClass: "box-body" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("a", { attrs: { href: "https://www.retextoar.com.br" } }, [
                _c("span", [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c("img", {
                      attrs: {
                        height: "40",
                        src: "https://storage.googleapis.com/retextoar-publico/app/imagens/logotipo_retextoar.png",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { attrs: { align: "center" } }, [
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c("div", { staticClass: "sucessful-text" }, [
                  _vm._v(" Compra realizada "),
                  _c("br"),
                  _vm._v(" com sucesso "),
                ]),
                _c("div", { attrs: { align: "center" } }, [
                  _c("br"),
                  _c("img", {
                    attrs: {
                      src: "https://storage.googleapis.com/retextoar-publico/app/imagens/ilustra06_confirmacaopagamento.png",
                    },
                  }),
                  _c("p", { staticStyle: { opacity: "0.7" } }, [
                    _vm._v("Olá,"),
                  ]),
                  _c("p", { staticStyle: { opacity: "0.7" } }, [
                    _vm._v("Seus créditos já foram adicionados."),
                  ]),
                  _c("br"),
                ]),
                _c("br"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-sm-12 button-container" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "next",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.onClickTranscriptionButton()
                      },
                    },
                  },
                  [_vm._v("Transcrições")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Compra Realizada com Sucesso")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }